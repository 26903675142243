<template>
  <div>
    <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn" />
    <div class="content m-t1">
      <div class="m-t1 m-b1">收款总金额<span class="m-l1" style="color:#365DD2">￥{{ totalAmount }}</span></div>
      <ly-table :columns="columns" ref="tableRef" size="small" rowKey="orderCode" :data="data" :pagination="pagination" @pageChange="pageChange" :loading="loading">
        <template #idx="{ index }">
          {{ index + 1 }}
        </template>
        <template #paymentStatus="{ record }">
          <span :style="{color:colorList[record.paymentStatus?.value]}">{{ record.paymentStatus?.label }}</span> 
        </template>
      </ly-table>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { sandpayPage, sandpayTotal } from '@/api/finance/income'
import { iptData, columns } from './collectionList'

const searchMsg = ref({})
const data = ref([])
const loading = ref(false)
const totalAmount = ref(0)
const colorList = ref({
  1:'#15ad31',
  0:'#d48310'
})
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
//分页事件
const pageChange = (e) => {
  pagination.value = e
  reqSandpayPage()
}
const searchAdd = (e) => {
  pagination.value.current = 1
  searchMsg.value = e
  reqSandpayPage()
}
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  reqSandpayPage()
}
const reqSandpayPage = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize
  }
  Object.assign(msg, searchMsg.value)
  sandpayPage(msg).then(res => {
    if (res.code !== 10000) return
    data.value = res.data.records
    console.log(data.value);
    pagination.value.total = res.data.total
  }).finally(() => {
    loading.value = false
  })
}
const reqSandpayTotal = () => {
  sandpayTotal().then(res => {
    if (res.code !== 10000) return
    totalAmount.value = res.data
  })
}
onMounted(() => {
  reqSandpayPage()
  reqSandpayTotal()
})
</script>  

<style lang="less" scoped>
.content {
  padding: 10px;
  background: #fff;
  height: calc(100vh - 230px);
}
</style>